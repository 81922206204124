import React from 'react'
import styles from './styles/Header.module.scss'
import { useRouter } from 'next/router'
import Image from 'next/image'
export default function Logo() {
    const router = useRouter()
  return (
        <div className={styles.header__logo} onClick={() => router.push('/')}>
            <h3>
                Mate <span>
                    Puro
                </span>
            </h3>
            <Image src="/hoja.png" alt="Logo" width={30} height={30} />
        </div>



  )
}
