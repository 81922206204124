import React , {useState, useEffect}from 'react'
import { useMediaQueries } from '@react-hook/media-query'


export default function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false)


    const {matches} = useMediaQueries({
        screen: 'screen',
        width: '(max-width: 1200px)',
        height: '(max-height: 768px)',

    })




    useEffect(() => {
        if (matches.width || matches.height) {
            setIsMobile(true)
        }
        else {
            setIsMobile(false)
        }

    }, [matches])

    return isMobile
}




