import React from 'react'
import styles from '../../../styles/Footer.module.scss'
import Image from 'next/image'
import Link from 'next/link'


export default function Footer() {
  return (
    <div className={styles.footer}>
    
        <div className={styles.footer__container}>
            <div className={styles.footer__item}>
                <Link href="/privacy" legacyBehavior={true}>
                Privacy Policy e Cookie Policy
                </Link>
            </div>      
        </div>
        <div className={styles.footer__logo}>
             <h3>
                Mate <span>
                    Puro
                </span>
            </h3>
            <Image src="/hoja.png" alt="Logo" width={30} height={30} />

        </div>
    </div>
  )
}
