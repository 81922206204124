import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Head from 'next/head';

export default function Layout({
  children,
  title = 'Título predeterminado',
  description = "Descripción predeterminada",
  keywords = 'palabra1, palabra2',
  url = 'https://www.matepuro.com',
  image = 'https://www.matepuro.com/images/og-image.jpg'
}) {
  return (
    <div>
     

      <header>
        <Header />
        <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="theme-color" content="#000000" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href={url} />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:locale:alternate" content="en_US" />
        <meta property="og:locale:alternate" content="es_ES" />
        <meta property="og:locale:alternate" content="it" />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content="La Pianta del Té" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="product:pretax_price:amount" content="2.049179" />
        <meta property="product:pretax_price:currency" content="EUR" />
        <meta property="product:price:amount" content="2.5" />
        <meta property="product:price:currency" content="EUR" />
        <script type="application/ld+json">

          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": title,
            "image": [image],
            "description": description,
            "offers": {
              "@type": "Offer",
              "priceCurrency": "EUR",
              "price": "2.5",
            }
          })}
        </script>
      </Head>
      </header>

      <main style={{display: 'flex'}}>
        {children}
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}
