import React from 'react'
import { nav__container, nav__item, nav__link } from './styles/Header.module.scss'
import Link from 'next/link'

function Nav() {
  return (
    <nav className={nav__container}>
      <ul>
        <li className={nav__item}>
          <Link href="/" legacyBehavior={true}>
            <a className={nav__link}>Home</a>
          </Link>
        </li>
        <li className={nav__item}>
          <Link href="/tazze" legacyBehavior={true}>
            <a className={nav__link}>Tazze</a>
          </Link>
        </li>
        <li className={nav__item}>
            <Link href="/yerbaMate" legacyBehavior={true}>
                <a className={nav__link}>Yerba Mate</a>
            </Link>
        </li>

      </ul>
    </nav>
  )
}

export default Nav
