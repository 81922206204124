import React from 'react'
import styles from './styles/Header.module.scss'
import Logo from './Logo'
import Nav from './Nav'

export default function Header() {

    
  return (
    <div className={styles.header}>
        <Logo />
        
        <nav className={styles.nav}>
          <Nav />
        </nav>


    </div>
  )
}
